import employeeService from '@/services/EmployeeService';

const createEmployee = async ({ commit }, employeeData) => {
  const employee = await employeeService.createEmployee(employeeData);
  commit('updateEmployee', employee);
};

const fetchEmployees = async ({ commit }) => {
  const employees = await employeeService.getEmployees();
  commit('setEmployees', employees);
  commit('setFilteredEmployees', employees);
};

const fetchEmployee = async ({ commit }, employeeId) => {
  const employee = await employeeService.getEmployee(employeeId);
  commit('setCurrentEmployee', employee);
};

const fetchEmployeeDataOptions = async ({ commit }) => {
  const options = await employeeService.getEmployeeDataOptions();
  commit('setEmployeeDataOptions', options);
};

const fetchRoles = async (context, userId) => {
  return employeeService.getRoles(userId);
};

const saveEmployee = async ({ commit }, employeeData) => {
  try {
    const employee = await employeeService.saveEmployee(employeeData);
    commit('setCurrentEmployee', employeeData);
    commit('updateEmployee', employee);
    return true;
  } catch {
    return false;
  }
};

const deleteEmployee = async ({ commit }, employeeId) => {
  await employeeService.deleteEmployee(employeeId);
  commit('setCurrentEmployee', null);
  commit('removeEmployee', employeeId);
};

const saveOnboardingProgress = async ({ commit }, employeeData) => {
  var success = await employeeService.saveOnboardingProgress(
    employeeData.userId,
    employeeData.settingsId,
    employeeData.data
  );

  if (success) {
    commit('setCurrentEmployeeOnboardingProgress', employeeData.progress);
  }

  return success;
};

const finalizeOnboarding = async ({ commit }, employeeId) => {
  try {
    const employee = await employeeService.finalizeOnboarding(employeeId);
    if (employee) {
      commit('updateEmployee', employee);
      return true;
    }
  } catch {
    return false;
  }

  return false;
};

const setRole = async (context, moduleData) => {
  await employeeService.setRole(
    moduleData.userId,
    moduleData.moduleName,
    moduleData.isEnabled
  );
};

const importEmployees = async () => {
  await employeeService.importEmployees();
};

const createReport = async () => {
  return await employeeService.createReport();
};

const setFilteredEmployees = ({ commit }, employees) => {
  commit('setFilteredEmployees', employees);
};

export default {
  createEmployee,
  fetchEmployee,
  fetchEmployees,
  fetchEmployeeDataOptions,
  fetchRoles,
  finalizeOnboarding,
  saveEmployee,
  deleteEmployee,
  saveOnboardingProgress,
  setRole,
  importEmployees,
  setFilteredEmployees,
  createReport
};
