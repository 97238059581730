const setWidgets = (state, widgets) => {
  state.widgets = widgets;
};

const setTasks = (state, tasks) => {
  state.tasks = tasks;
};

const setDates = (state, dates) => {
  state.dates = dates;
};

const setTasksUpdated = (state, updated) => {
  state.tasksUpdated = updated;
};

const setDatesUpdated = (state, updated) => {
  state.datesUpdated = updated;
};

export default {
  setWidgets,
  setTasks,
  setDates,
  setTasksUpdated,
  setDatesUpdated
};
