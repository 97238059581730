const setOrganizations = (state, organizations) => {
  state.organizations = organizations;
};

const setOnboardingSettings = (state, settings) => {
  state.onboardingSettings = settings;
};

export default {
  setOrganizations,
  setOnboardingSettings
};
