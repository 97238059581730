import { VAlert } from 'vuetify/lib/components/VAlert';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"transition":"scale-transition","offset-y":"","content-class":"elevation-0","close-on-content-click":false,"nudge-top":5},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var args = ref.args;
return [_c(VTextField,_vm._b({staticClass:"search-field",attrs:{"prepend-inner-icon":"mdi-magnify","hide-details":"","dense":"","placeholder":_vm.$t('searchApplication'),"solo":""},on:{"keyup":_vm.search},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}},'v-text-field',args,false))]}}]),model:{value:(_vm.showResults),callback:function ($$v) {_vm.showResults=$$v},expression:"showResults"}},[_c(VSheet,{staticClass:"search-field-result pa-4"},[_c(VSkeletonLoader,{directives:[{name:"show",rawName:"v-show",value:(_vm.searching),expression:"searching"}],attrs:{"loading":"","type":"paragraph"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searching && _vm.results),expression:"!searching && results"}]},[_c(VList,[_vm._l((_vm.results),function(item){return [(item.type === 'user')?_c('userItem',{key:("search_user_" + (item.id)),attrs:{"item":item},on:{"click":_vm.clear}}):_vm._e()]})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.numResults > _vm.maxInitialResults),expression:"numResults > maxInitialResults"}]},[_vm._v(" "+_vm._s(_vm.numResults - _vm.maxInitialResults)+" "+_vm._s(_vm.$t('searchMoreMatches'))+" ")])],1),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(!_vm.searching && _vm.results.length === 0),expression:"!searching && results.length === 0"}],attrs:{"type":"warning","text":""}},[_vm._v(" "+_vm._s(_vm.$t('searchNoMatches'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }