<template>
  <div style="height: 100%">
    <v-menu
      v-if="customColors"
      bottom
      left
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text height="100%">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            {{ $t('theme.darkmode') }}
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="darkMode" color="secondary"></v-switch>
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>{{
            $t('theme.primaryColor')
          }}</v-list-item-content>
          <v-list-item-action
            ><color-picker
              v-model="currentTheme.primary"
              @edit="editItem(currentTheme.primary, 'primary')"
            ></color-picker
          ></v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>{{
            $t('theme.secondaryColor')
          }}</v-list-item-content>
          <v-list-item-action
            ><color-picker
              v-model="currentTheme.secondary"
              @edit="editItem(currentTheme.secondary, 'secondary')"
            ></color-picker
          ></v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>{{ $t('theme.accent') }}</v-list-item-content>
          <v-list-item-action
            ><color-picker
              v-model="currentTheme.accent"
              @edit="editItem(currentTheme.accent, 'accent')"
            ></color-picker
          ></v-list-item-action>
        </v-list-item>

        <v-list-item v-show="!darkMode">
          <v-list-item-content>{{
            $t('theme.background')
          }}</v-list-item-content>
          <v-list-item-action
            ><color-picker
              v-model="currentTheme.background"
              @edit="editItem(currentTheme.background, 'background')"
            ></color-picker
          ></v-list-item-action>
        </v-list-item>

        <v-list-item v-show="editingItem && editingType" class="text-center">
          <v-list-item-content class="justify-center">
            <v-color-picker v-model="editingItem" hide-inputs></v-color-picker>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on }">
        <v-btn text height="100%" v-on="on" @click.stop="darkMode = !darkMode">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>

      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import colorPicker from '@/components/misc/color-picker-field';

export default {
  props: {
    customColors: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      darkMode: false,
      editingItem: null,
      editingType: ''
    };
  },

  computed: {
    icon() {
      if (this.darkMode) {
        return 'mdi-moon-waning-crescent';
      } else {
        return 'mdi-white-balance-sunny';
      }
    },

    currentTheme() {
      if (this.darkMode) {
        return this.$vuetify.theme.themes.dark;
      } else {
        return this.$vuetify.theme.themes.light;
      }
    },

    primary() {
      return this.currentTheme.primary;
    },

    tooltip() {
      if (this.darkMode) {
        return this.$t('switchToLightMode');
      } else {
        return this.$t('switchToDarkMode');
      }
    }
  },

  watch: {
    darkMode() {
      this.setDarkMode(this.darkMode);
      this.$vuetify.theme.dark = this.darkMode;
    },

    editingItem() {
      this.currentTheme[this.editingType] = this.editingItem;
    }
  },

  methods: {
    ...mapMutations({
      setDarkMode: 'setDarkMode'
    }),

    editItem(item, type) {
      this.editingItem = item;
      this.editingType = type;
    }
  },

  components: {
    colorPicker
  }
};
</script>
