// import Vue from 'vue';

// import vuetify from './plugins/vuetify';
// import components from '@hp/hp-components';

// import App from './App.vue';
// import router from './router';
// import i18n from './i18n';
// import store from './store';

// // import 'vuetify/dist/vuetify.min.css';
// import '@hp/hp-components/dist/style.css';

import { filters } from '@/filters/filters.js';

Vue.filter('highlight', filters.highlight);

import { localizationMixin } from '@/mixins/localization.js';
Vue.mixin(localizationMixin);

// Vue.config.productionTip = false;

// // Vue.directive('click-outside', {
// //   bind: function (el, binding, vnode) {
// //     el.clickOutsideEvent = function (event) {
// //       // here I check that click was outside the el and his children
// //       if (!(el == event.target || el.contains(event.target))) {
// //         // and if it did, call method provided in attribute value
// //         if (typeof vnode.context[binding.expression] === 'function') {
// //           vnode.context[binding.expression](event);
// //         }
// //       }
// //     };
// //     document.body.addEventListener('click', el.clickOutsideEvent);
// //   },
// //   unbind: function (el) {
// //     document.body.removeEventListener('click', el.clickOutsideEvent);
// //   }
// // });

// // const v = new vuetify({
// //   theme: {
// //     options: {
// //       customProperties: true
// //     },
// //     dark: false,
// //     themes: {
// //       light: {
// //         background: '#F2F3F8',
// //         primary: '#002776',
// //         secondary: '#009fda'
// //       }
// //     }
// //   }
// // });

// Vue.use(components);

// console.log(vuetify);

// new Vue({
//   vuetify,
//   router,
//   i18n,
//   store,
//   render: (h) => h(App)
// }).$mount('#app');

// import components from '@hp/hp-components';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import components from '@hp/hp-components';

import router from './router';
import i18n from './i18n';
import store from './store';

import App from './App.vue';

import 'vuetify/dist/vuetify.min.css';
import '@hp/hp-components/dist/style.css';

Vue.config.productionTip = false;
// Vue.use(hpComponents);

// Vue.use(components, { vuetify });
Vue.use(components, { vuetify });

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: (h) => h(App)
}).$mount('#app');
