const setEmployees = (state, employees) => {
  state.employees = employees;
  state.employeesLoaded = true;
};

const removeEmployee = (state, employeeId) => {
  state.employees = state.employees.filter((x) => x.id !== employeeId);
};

const updateEmployee = (state, employee) => {
  state.employees = state.employees.filter((x) => x.id !== employee.id);
  state.employees.push(employee);
};

const setCurrentEmployee = (state, employee) => {
  state.currentEmployee = employee;
};

const setCurrentEmployeeOnboardingProgress = (state, progress) => {
  if (state.currentEmployee) {
    state.currentEmployee.OnboardingProgress = progress;
  }
};

const setEmployeeDataOptions = (state, options) => {
  options.locations.forEach((x) => x.translation.forEach((t) => (t.id = x.id)));
  options.nations.forEach((x) => x.translation.forEach((t) => (t.id = x.id)));
  options.positions.forEach((x) => x.translation.forEach((t) => (t.id = x.id)));
  options.hierarchies.forEach((x) =>
    x.translation.forEach((t) => (t.id = x.id))
  );
  options.skills.forEach((x) => x.translation.forEach((t) => (t.id = x.id)));
  state.employeeDataOptions = options;
};

const setFilteredEmployees = (state, employees) => {
  state.filteredEmployees = employees;
};

export default {
  setCurrentEmployee,
  updateEmployee,
  removeEmployee,
  setEmployees,
  setEmployeeDataOptions,
  setCurrentEmployeeOnboardingProgress,
  setFilteredEmployees
};
