<template>
  <v-avatar size="60" class="mt-2">
    <api-image
      v-if="imageFileName"
      :bucket="imageBucket"
      :file-name="imageFileName"
    />
    <span v-else>{{ initials }}</span>
  </v-avatar>
</template>

<script>
import apiImage from '@/components/misc/api-image.vue';
export default {
  props: {
    picture: {
      type: String,
      default: () => null
    },
    email: {
      type: String,
      defaul: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    }
  },
  computed: {
    initials() {
      if (this.firstName) {
        return this.firstName.split('')[0] + this.lastName?.split('')[0] ?? '';
      } else if (this.email) {
        return this.email.split('')[0];
      } else {
        return 'JD';
      }
    },
    bcNumber() {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      const initialsArray = this.initials.split('');

      var bcNumber = 0;
      initialsArray.forEach((x) => {
        bcNumber += alphabet.indexOf(x.toString());
      });

      return Math.floor(bcNumber / initialsArray.length);
    },
    className() {
      let cName = 'bc-' + this.bcNumber + ' size-' + this.size;

      if (this.picture) {
        cName += ' has-picture';
      }

      return cName;
    },

    pictureObj() {
      if (
        this.picture &&
        this.picture.indexOf('{') >= 0 &&
        this.picture.indexOf('}') >= 0
      ) {
        return JSON.parse(this.picture);
      }

      return null;
    },

    imageFileName() {
      return this.pictureObj?.FileName ?? 'default_avatar.jpg';
    },

    imageBucket() {
      return this.pictureObj?.Bucket ?? 'public';
    }
  },

  components: {
    apiImage
  }
};
</script>
