import api from '@/helpers/api.js';

export default {
  createEmployee(employeeData) {
    return api.post('/user', employeeData);
  },

  async getEmployees() {
    const response = await api.get('/users');
    const users = response.users;
    return users;
  },

  async getEmployee(userId) {
    const response = await api.get(`/users/${userId}`);
    return response.user;
  },

  async getEmployeeDataOptions() {
    return await api.get('/users/options');
  },

  getRoles(userId) {
    return api.get(`/roles/${userId}`);
  },

  async saveEmployee(data) {
    return await api.post('/users', data);
  },

  async deleteEmployee(userId) {
    return await api.post(`/users/${userId}`);
  },

  async saveOnboardingProgress(userId, settingsId, data) {
    return await api.post(`/users/onboarding`, {
      UserId: userId,
      OnboardingSettingsId: settingsId,
      OnboardingData: data
    });
  },

  async finalizeOnboarding(userId) {
    return await api.post(`/users/onboarding/${userId}`);
  },

  async importEmployees() {
    return await api.post('/users/import');
  },

  setRole(userId, roleName, isEnabled) {
    return api.put('/roles', {
      UserId: userId,
      Role: roleName,
      IsEnabled: isEnabled
    });
  },

  async createReport() {
    return api.download('/users/report');
  }
};
