const filters = {
  highlight: function (value, highlightText) {
    if (!highlightText?.trim()) {
      return value;
    }

    if (value.toLowerCase().indexOf(highlightText.toLowerCase()) <= -1) {
      return value;
    }

    var reg = new RegExp('(' + highlightText + ')', 'gi');
    return value.replace(reg, '<span class="highlighted">$1</span>');
  }
};

export { filters };
