import filesService from '@/services/FilesService';

const getFileData = async ({ state, commit }, fileKey) => {
  const cachedFile = state.filesCache.find(
    (x) => x.bucket === fileKey.bucket && x.fileName === fileKey.fileName
  );

  if (cachedFile) {
    return cachedFile.fileData;
  }

  const fileData = await filesService.getFileData(
    fileKey.bucket,
    fileKey.fileName
  );

  commit('addFileDataToCache', {
    bucket: fileKey.bucket,
    fileName: fileKey.fileName,
    fileData: fileData
  });

  return fileData;
};

const saveFile = async ({ commit }, fileInfo) => {
  const fileData = await filesService.saveFile(
    fileInfo.bucket,
    fileInfo.data,
    fileInfo.employeeId
  );

  commit('addFileDataToCache', {
    bucket: fileInfo.bucket,
    fileName: fileInfo.data.fileName,
    fileData: fileData
  });

  return fileData;
};

const deleteFile = async (context, fileKey) => {
  await filesService.deleteFile(fileKey.bucket, fileKey.fileName);
};

export default {
  getFileData,
  saveFile,
  deleteFile
};
