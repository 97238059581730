// import Vue from 'vue';
// import Vuetify from 'vuetify';

// import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// import 'tiptap-vuetify/dist/main.css';

// Vue.use(Vuetify);

// const vuetify = new Vuetify();
// Vue.use(TiptapVuetifyPlugin, {
//   vuetify,
//   iconsGroup: 'mdi'
// });

// // Vue.use(hpComponents);

// export default new Vuetify({
//   theme: {
//     options: {
//       customProperties: true
//     },
//     dark: false,
//     themes: {
//       light: {
//         background: '#F2F3F8',
//         primary: '#002776',
//         secondary: '#009fda'
//       }
//     }
//   }
// });

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        background: '#F2F3F8',
        primary: '#002776',
        secondary: '#009fda'
      }
    }
  }
});
