const getEmployeePageLayout = (state) => {
  return state.employeePageLayout;
};

const getEmployeeSummaryLayout = (state) => {
  return state.employeeSummaryLayout;
};

const getEmployeeWidgetLayout = (state) => {
  return state.employeeWidgetLayout;
};

export default {
  getEmployeePageLayout,
  getEmployeeSummaryLayout,
  getEmployeeWidgetLayout
};
