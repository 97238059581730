import dashboardService from '@/services/DashboardService';

const saveWidgets = async (context, widgets) => {
  await dashboardService.saveWidgets(widgets);
};

const setTasksUpdated = ({ commit }) => {
  commit('setTasksUpdated', true);
};

const setDatesUpdated = ({ commit }) => {
  commit('setDatesUpdated', true);
};

const getTasks = async ({ state, commit }) => {
  if (state.tasksUpdated) {
    commit('setTasksUpdated', false);
    const tasks = await dashboardService.getTasks();
    commit('setTasks', tasks);
  }
  return state.tasks;
};

const setTaskVisibility = async (context, task) => {
  await dashboardService.setTaskVisibility(task);
};

const getDates = async ({ state, commit }) => {
  if (state.datesUpdated) {
    commit('setDatesUpdated', false);
    const dates = await dashboardService.getDates();
    commit('setDates', dates);
  }
  return state.dates;
};

const getWidgets = async ({ state, commit }) => {
  if (!state.widgets || state.widgets.length === 0) {
    const widgets = await dashboardService.getWidgets();
    commit('setWidgets', widgets);
  }

  return state.widgets;
};

const generateBirthdays = async () => {
  await dashboardService.generateBirthdays();
};

export default {
  getTasks,
  setTaskVisibility,
  getDates,
  getWidgets,
  saveWidgets,
  setTasksUpdated,
  setDatesUpdated,
  generateBirthdays
};
