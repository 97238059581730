const getEmployees = (state) => state.employees;
const getOnboardingEmployees = (state) =>
  state.employees.filter((x) => x.onboarding);
const getCurrentEmployee = (state) => state.currentEmployee;
const getEmployeeDataOptions = (state) => state.employeeDataOptions;
const getEmployeesLoaded = (state) => state.employeesLoaded;
const getFilteredEmployees = (state) => state.filteredEmployees;

export default {
  getCurrentEmployee,
  getEmployees,
  getOnboardingEmployees,
  getEmployeeDataOptions,
  getEmployeesLoaded,
  getFilteredEmployees
};
