export const localizationMixin = {
  methods: {
    localize(item) {
      if (item.length === 0 || !Array.isArray(item)) {
        return '';
      }

      let langKey = 'de-DE';

      if (this.$i18n && this.$i18n.locale === 'en') {
        langKey = 'en-US';
      }

      const translation = item.filter((x) => x.languageKey === langKey);
      if (translation.length > 0) {
        return translation[0].translation;
      }

      return item[0].translation;
    }
  }
};
