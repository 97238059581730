import organizationService from '@/services/OrganizationService';

const createOrganization = async (context, organizationData) => {
  const result = await organizationService.createOrganization(organizationData);
  return result;
};

const fetchOrganizations = async ({ commit }) => {
  const organizations = await organizationService.getOrganizations();
  commit('setOrganizations', organizations);
};

const fetchOnboardingSettings = async ({ commit }) => {
  const onboardingSettings = await organizationService.getOnboardingSettings();
  commit('setOnboardingSettings', onboardingSettings);
};

export default {
  createOrganization,
  fetchOrganizations,
  fetchOnboardingSettings
};
