import api from '@/helpers/api.js';

export default {
  async getTasks() {
    return await api.get('/Dashboard/GetTasks');
  },

  async getDates() {
    return await api.get('/Dashboard/GetDates');
  },

  async getWidgets() {
    return await api.get('/Dashboard/GetWidgets');
  },

  async saveWidgets(data) {
    var widgetData = JSON.stringify(data);
    await api.post('/Dashboard/SaveWidgets', widgetData);
  },

  async setTaskVisibility(task) {
    await api.post(`/Dashboard/SetTaskVisibility`, task);
  },

  async generateBirthdays() {
    await api.post('/Dashboard/GenerateBirthdays');
  }
};
