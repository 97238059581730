<template>
  <transition name="fade">
    <div id="loading-layer" v-if="isLoading">
      <div class="box">
        <div class="center" style="width: 200px; text-align: center">
          {{ info }}
          <v-progress-linear
            v-if="value < 0"
            indeterminate
            rounded
            height="10"
          ></v-progress-linear>
          <v-progress-linear
            v-else
            :value="value"
            rounded
            height="10"
          ></v-progress-linear>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: -1
    },
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      progress: this.value
    };
  },
  mounted() {
    if (this.isLoading) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  },
  computed: {
    isLoading() {
      return this.visible || (this.progress >= 0 && this.progress <= 100);
    }
  },
  watch: {
    isLoading(value) {
      if (value) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    },
    value(value) {
      if (value >= 100) {
        var self = this;
        setTimeout(function () {
          self.progress = value + 1;
        }, 200);
      } else {
        this.progress = value;
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss">
#loading-layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #fff;

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
</style>
