<template>
  <v-text-field v-model="color" hide-details class="ma-0 pa-0" flat solo>
    <template v-slot:append>
      <!-- <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      > -->
      <template>
        <div :style="swatchStyle" @click="$emit('edit')" />
      </template>
      <!-- <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="color" flat />
          </v-card-text>
        </v-card> 
      </v-menu>-->
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      menu: false,
      color: '',
      mask: '!#XXXXXXXX'
    };
  },

  computed: {
    swatchStyle() {
      const { color } = this;
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: '4px',
        transition: 'border-radius 200ms ease-in-out'
      };
    }
  },

  watch: {
    color() {
      this.value = this.color;
      this.$emit('input', this.color);
    },

    value() {
      this.color = this.value;
    }
  },

  mounted() {
    this.color = this.value;
  },

  methods: {},

  components: {}
};
</script>
