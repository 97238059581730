<template>
  <v-img :src="imageSrc" class="flex-grow-0" />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    bucket: {
      type: String,
      required: false,
      default: 'public'
    },

    fileName: {
      type: String,
      required: true
    },

    src: {
      type: String,
      required: false,
      default: null
    }
  },

  data: () => ({
    imageSrc: ''
  }),

  watch: {
    src() {
      if (this.src && this.src.trim().length > 0) {
        this.imageSrc = this.src;
      }
    }
  },

  async mounted() {
    if (this.bucket && this.fileName) {
      const imageData = await this.getFileData({
        bucket: this.bucket,
        fileName: this.fileName
      });

      this.imageSrc = `data:${imageData.contentType};base64, ${imageData.fileContent}`;
    }
  },

  methods: {
    ...mapActions({
      getFileData: 'files/getFileData'
    })
  }
};
</script>
<style scoped>
.v-image {
  max-height: 200px;
  max-width: 200px;
}
</style>
