const addFileDataToCache = (state, file) => {
  const fileIndex = state.filesCache.findIndex(
    (x) => x.bucket === file.bucket && x.fileName === file.fileName
  );

  if (fileIndex > 0) {
    state.filesCache[fileIndex].fileData = file.fileData;
  } else {
    state.filesCache.push(file);
  }
};

export default {
  addFileDataToCache
};
