import layoutService from '@/services/LayoutService';

const fetchEmployeePageLayout = async ({ commit }) => {
  const layout = await layoutService.getEmployeePageLayout();
  commit('setEmployeePageLayout', layout);
};

const fetchEmployeeSummaryLayout = async ({ commit }) => {
  const layout = await layoutService.getEmployeeSummaryLayout();
  commit('setEmployeeSummaryLayout', layout);
};

const fetchEmployeeWidgetLayout = async ({ commit }) => {
  const layout = await layoutService.getEmployeeWidgetLayout();
  commit('setEmployeeWidgetLayout', layout);
};

const saveEmployeePageLayout = async ({ commit }, layout) => {
  await layoutService.saveEmployeePageLayout(layout);
  commit('setEmployeePageLayout', layout);
};

const saveEmployeeSummarylayout = async () => {};

export default {
  fetchEmployeePageLayout,
  fetchEmployeeSummaryLayout,
  fetchEmployeeWidgetLayout,
  saveEmployeePageLayout,
  saveEmployeeSummarylayout
};
