const setEmployeePageLayout = (state, layout) => {
  state.employeePageLayout = layout;
};

const setEmployeeSummaryLayout = (state, layout) => {
  state.employeeSummaryLayout = layout;
};

const setEmployeeWidgetLayout = (state, layout) => {
  state.employeeWidgetLayout = layout;
};

export default {
  setEmployeePageLayout,
  setEmployeeSummaryLayout,
  setEmployeeWidgetLayout
};
