<template>
  <v-list-item
    :to="{
      name: 'EmployeeDetails',
      params: { id: item.id }
    }"
    @click="$emit('click')"
  >
    <v-list-item-content>
      <v-list-item-title
        >{{ item.firstName }} {{ item.lastName }}</v-list-item-title
      >
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
