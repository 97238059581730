import AuthService from '@/services/AuthService.js';
const apiUrl = process.env.VUE_APP_HP_EMT_API;

export default {
  async download(path, params) {
    const authService = new AuthService();
    const user = await authService.getUser();

    if (user?.access_token) {
      var requestUrl = new URL(`${apiUrl}${path}`);
      if (params) {
        Object.keys(params).forEach((key) =>
          requestUrl.searchParams.append(key, params[key])
        );
      }

      const response = await fetch(requestUrl, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        })
      });

      if (response.ok) {
        const blob = response.blob();
        return blob;
      } else if (response.status === 401) {
        await authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else if (response.status === 404) {
        throw new Error('not found');
      } else {
        // TODO: handle exception
      }
    } else {
      await authService.login();
    }

    return null;
  },

  async get(path, params) {
    const authService = new AuthService();
    const user = await authService.getUser();

    if (user?.access_token) {
      const requestUrl = new URL(`${apiUrl}${path}`);
      if (params) {
        Object.keys(params).forEach((key) =>
          requestUrl.searchParams.append(key, params[key])
        );
      }

      const response = await fetch(requestUrl, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        })
      });

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        await authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
      }
    } else {
      await authService.login();
    }

    return null;
  },

  async put(path, params) {
    const authService = new AuthService();
    const user = await authService.getUser();

    if (user?.access_token) {
      const requestUrl = new URL(`${apiUrl}${path}`);

      const requestOptions = {
        method: 'put',
        headers: new Headers({
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + user.access_token
        })
      };

      if (params) {
        requestOptions.body = JSON.stringify(params);
      }

      const response = await fetch(requestUrl, requestOptions);

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
        // window.location.href = '/#/error-500';
      }
    } else {
      authService.login();
    }

    return null;
  },

  async post(path, params) {
    const authService = new AuthService();
    const user = await authService.getUser();

    if (user?.access_token) {
      const requestUrl = new URL(`${apiUrl}${path}`);

      const requestOptions = {
        method: 'post',
        headers: new Headers({
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + user.access_token
        })
      };

      if (params) {
        requestOptions.body = JSON.stringify(params);
      }

      const response = await fetch(requestUrl, requestOptions);

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
        // window.location.href = '/#/error-500';
      }
    } else {
      authService.login();
    }

    return null;
  },

  async postForm(path, params) {
    const authService = new AuthService();
    const user = await authService.getUser();

    if (user?.access_token) {
      const requestUrl = new URL(`${apiUrl}${path}`);

      const formData = new FormData();
      for (const [key, value] of Object.entries(params)) {
        formData.append(key, value);
      }

      const requestOptions = {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        }),
        body: formData
      };

      const response = await fetch(requestUrl, requestOptions);

      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      } else {
        // TODO: handle exception
        // window.location.href = '/#/error-500';
      }
    } else {
      authService.login();
    }

    return null;
  },

  async delete(path, params) {
    const authService = new AuthService();
    const user = await authService.getUser();

    if (user?.access_token) {
      const requestUrl = new URL(`${apiUrl}${path}`);
      if (params) {
        Object.keys(params).forEach((key) =>
          requestUrl.searchParams.append(key, params[key])
        );
      }

      const response = await fetch(requestUrl, {
        method: 'delete',
        headers: new Headers({
          Authorization: 'Bearer ' + user.access_token
        })
      });

      if (!response.ok) {
        // TODO: handle exceptions
      } else if (response.status === 401) {
        authService.login();
      } else if (response.status === 403) {
        window.location.href = '/#/error-403';
      }
    } else {
      authService.login();
    }

    return null;
  }
};
