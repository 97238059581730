import api from '@/helpers/api.js';

export default {
  async getEmployeePageLayout() {
    var response = await api.get('/layouts/user');
    return response.layout;
  },

  async getEmployeeSummaryLayout() {
    var response = await api.get('/layouts/summary');
    return response.layout;
  },

  async getEmployeeWidgetLayout() {
    var response = await api.get('/layouts/users');
    return response.layout;
  },

  async saveEmployeePageLayout(layout) {
    await api.post('/layouts/user', layout);
  }
};
