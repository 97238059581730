import Vue from 'vue';
import Vuex from 'vuex';

import employeesModule from './modules/employees/module';
import layoutsModule from './modules/layouts/module';
import organizationsModule from './modules/organizations/module';
import dashboardModule from './modules/dashboard/module';
import filesModule from './modules/files/module';

import sessionService from '../services/SessionService';
import themeService from '@/services/ThemeService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentOrganization: null,
    currentUser: null,
    languageKey: 'de-DE',
    notifications: [],
    dashboardSettings: {},
    darkMode: false,
    authorizationProgress: 20,
    authorizationInfo: 'Sitzung wird eingerichtet',
    userManager: null,
    dateFormat: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    datetimeFormat: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    loading: true
  },
  getters: {
    currentOrganization: (state) => state.currentOrganization,
    currentUser: (state) => state.currentUser,
    languageKey: (state) => state.languageKey ?? 'de-DE',
    notifications: (state) => state.notifications,
    newNotificationsCount: (state) =>
      state.notifications.filter((x) => !x.seen).length,
    dashboardSettings: (state) => state.dashboardSettings,
    authorizationProgress: (state) => state.authorizationProgress,
    authorizationInfo: (state) => state.authorizationInfo,
    dateFormat: (state) => state.dateFormat,
    datetimeFormat: (state) => state.datetimeFormat,
    loading: (state) => state.loading
  },
  mutations: {
    setAuthorization(state, params) {
      state.authorizationProgress = params.progress;
      if (params.info) {
        state.authorizationInfo = params.info;
      }
    },
    initializeSession(state, session) {
      state.currentOrganization = session.currentOrganization;
      state.currentUser = session.currentUser;
      state.languageKey = session.languageKey;
      // state.notifications = session.notifications;
      state.dashboardSettings = session.dashboardSettings;
    },
    setLanguage(state, languageKey) {
      state.languageKey = languageKey;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setNotificationsSeen(state) {
      state.notifications = state.notifications.map((x) => {
        x.seen = true;
        return x;
      });
    },
    setLoading(state, loading) {
      state.loading = loading;
    },

    setDarkMode(state, value) {
      state.darkMode = value;
    }
  },
  actions: {
    async setAuthorization({ commit }, params) {
      await commit('setAuthorization', params);
    },
    async initializeSession({ commit }) {
      var session = await sessionService.initialize();

      await commit('setAuthorization', {
        progress: 75,
        info: 'Sitzung wird gespeichert'
      });

      await commit('initializeSession', session);

      await commit('setAuthorization', {
        progress: 100,
        info: 'Erfolgreich!'
      });
    },
    async setLanguage({ commit }, languageKey) {
      await commit('setLanguage', languageKey);
      await sessionService.setLanguage(languageKey);
    },
    async setLoading({ commit }, loading) {
      await commit('setLoading', loading);
    },

    async getThemeColors(context, slug) {
      return await themeService.getTheme(slug);
    }
  },
  modules: {
    employees: employeesModule,
    layouts: layoutsModule,
    organizations: organizationsModule,
    dashboard: dashboardModule,
    files: filesModule
  }
});
