<template>
  <v-app
    class="hp-application"
    :class="{ 'hp-application--has-hero-bar': $route.meta.hasHeroBar }"
  >
    <loading-layer :value="authorizationProgress" :info="authorizationInfo" />

    <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

    <v-app-bar flat app fixed clipped-left v-if="false">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <img
        :src="require(`@/assets/${organizationSlug}-logo.png`)"
        style="height: 80px"
        v-if="$vuetify.breakpoint.mdAndUp && organizationSlug"
      />

      <v-spacer></v-spacer>

      <fulltext-search />

      <theme-switch
        v-if="$vuetify.breakpoint.mdAndUp"
        :custom-colors="false"
      ></theme-switch>
    </v-app-bar>
    <hp-app-bar @signOut="logout">
      <template v-slot:navigation>
        <router-link
          v-for="(item, index) in menuItems"
          :key="`menu_item_${index}`"
          :to="item.route"
        >
          <hp-button text small :prepend-icon="item.icon" class="mr-4">
            {{ item.label }}
          </hp-button>
        </router-link>
      </template>
      <template v-slot:search>
        <fulltext-search />
        <v-menu
          v-model="languageMenu"
          bottom
          left
          offset-y
          :close-on-content-click="false"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on: onMenu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="{ ...onMenu, ...onTooltip }"
                  height="100%"
                >
                  <img :src="getFlag(selectedLanguage)" class="mr-2" />
                </v-btn>
              </template>
              <span>{{ $t('language') }}</span>
            </v-tooltip>
          </template>
          <v-list dense class="v-toolbar">
            <v-list-item
              :key="lang.key"
              v-for="lang in languages"
              @click="selectLanguage(lang)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <img :src="getFlag(lang)" class="mr-2" />
                  {{ $t(`languages.${lang.shortKey}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </hp-app-bar>

    <v-navigation-drawer
      v-if="false"
      v-model="drawer"
      :width="drawerWidth"
      app
      left
      clipped
    >
      <v-list two-line dense elevation="3" class="ma-4 mt-6 v-list--avatar">
        <v-list-item
          :to="this.currentUser && `/employees/${this.currentUser.id}`"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text avatar-title">
              {{ firstName + ' ' + lastName }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text font-weight-regular">{{
              $t('showProfile')
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <profile-avatar
            v-if="currentUser"
            :picture="picture"
            :firstName="firstName"
            :lastName="lastName"
            :email="email"
          />
        </v-list-item>
      </v-list>

      <div class="overline pl-4 mt-6 font-weight-bold">Navigation</div>

      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item :to="{ name: 'Dashboard' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'EmployeesOverview' }">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('employees') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-if="isAdmin">
        <div class="overline pl-4 mt-6 font-weight-bold">Admin</div>

        <v-list nav dense>
          <v-list-item-group v-model="group2">
            <v-list-item :to="{ name: 'OnboardingOverview' }">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-clipboard-check-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('onboarding.name')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <!---<v-list-item-group v-model="group2">
            <v-list-item :to="{ name: 'DetailsLayout' }">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-text-box-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('layouts.name') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>-->

          <v-list-item-group v-model="group2">
            <v-list-item :to="{ name: 'EmailOverview' }">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-email-fast</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t('emailsOverview')
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>

    <page-title></page-title>

    <v-main>
      <v-row>
        <v-col cols="12">
          <v-container fluid class="pa-0 ma-0">
            <router-view />
          </v-container> </v-col
      ></v-row>
    </v-main>
  </v-app>
</template>

<script>
import AuthService from '@/services/AuthService.js';
import pageTitle from '@/components/main/PageTitle';
import themeSwitch from '@/components/main/ThemeSwitch';
import fulltextSearch from '@/components/main/FulltextSearch';
import profileAvatar from '@/components/main/ProfileAvatar';
import loadingLayer from '@/components/misc/loading-layer';

import('@/assets/styles/default.scss');
import('@/assets/styles/theme-default.scss');

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
    pageTitle,
    profileAvatar,
    loadingLayer,
    themeSwitch,
    fulltextSearch
  },

  computed: {
    ...mapGetters({
      currentOrganization: 'currentOrganization',
      currentUser: 'currentUser',
      notifications: 'notifications',
      newNotificationsCount: 'newNotificationsCount',
      authorizationProgress: 'authorizationProgress',
      authorizationInfo: 'authorizationInfo',
      languageKey: 'languageKey'
    }),
    organizationSlug() {
      return this.currentOrganization?.slug;
    },
    picture() {
      return this.currentUser?.picture;
    },
    firstName() {
      return this.currentUser?.firstName ?? '';
    },
    lastName() {
      return this.currentUser?.lastName ?? '';
    },
    email() {
      return this.currentUser?.email ?? '';
    },
    isAdmin() {
      return this.currentUser?.isAdmin ?? false;
    },
    selectedLanguage: {
      get() {
        return (
          this.languages.find((x) => x.key === this.languageKey) ?? {
            key: this.languageKey,
            shortKey: this.languageKey
          }
        );
      },
      set(value) {
        if (value.key) {
          this.setLanguage(value.key);
          this.$i18n.locale = value.shortKey;
        } else {
          this.setLanguage(value);
          this.$i18n.locale = value.substring(0, value.indexOf('-'));
        }
      }
    },

    menuItems() {
      const items = [];

      items.push({
        icon: 'mdi-view-dashboard',
        label: 'Dashboard',
        route: { name: 'Dashboard' }
      });

      items.push({
        icon: 'mdi-account-multiple',
        label: this.$t('employees'),
        route: { name: 'EmployeesOverview' }
      });

      items.push({
        icon: 'mdi-clipboard-check-multiple',
        label: this.$t('onboarding.name'),
        route: { name: 'OnboardingOverview' }
      });

      items.push({
        icon: 'mdi-chart-bar',
        label: this.$t('statistics.name'),
        route: { name: 'StatisticsOverview' }
      });

      return items;
    }
  },

  async beforeMount() {
    if (
      !/callback/.test(window.location.href) &&
      !/silent-renew/.test(window.location.href) &&
      !/error-403/.test(window.location.href)
    ) {
      await this.initializeSession();
    }
  },

  async mounted() {
    this.authService = new AuthService();
  },

  data: () => ({
    authService: null,
    drawer: true,
    drawerWidth: 280,
    group: null,
    group2: null,
    languageMenu: false,
    languages: [
      { key: 'de-DE', shortKey: 'de' },
      { key: 'en-US', shortKey: 'en' }
    ]
  }),

  watch: {
    async currentOrganization(newOrganization) {
      this.$i18n.locale = this.languageKey.substring(
        0,
        this.languageKey.indexOf('-')
      );

      if (newOrganization) {
        const theme = await this.getThemeColors();
        this.$vuetify.theme.themes.light.primary = theme.light.accent;
        this.$vuetify.theme.themes.light.secondary = theme.light.secondary;
        this.$vuetify.theme.themes.light.accent = theme.light.accent;
        this.$vuetify.theme.themes.light.background = theme.light.background;
        this.$vuetify.theme.themes.dark.primary = theme.dark.primary;
        this.$vuetify.theme.themes.dark.secondary = theme.dark.secondary;
        this.$vuetify.theme.themes.dark.accent = theme.dark.accent;
        document.title = `${newOrganization.slug.toUpperCase()} - Employee Management Tool`;
      } else {
        document.title = `hidden professionals - Employee Management Tool`;
      }
    },

    async currentUser() {
      this.setLoading(true);
      // fetch options and layout information
      await Promise.allSettled([
        //this.fetchOrganizations(),
        this.fetchOnboardingSettings(),
        this.fetchEmployeeDataOptions(),
        this.fetchEmployeePageLayout(),
        this.fetchEmployeeWidgetLayout(),
        this.fetchEmployeeSummaryLayout(),
        this.fetchEmployees()
      ]);

      this.setLoading(false);
    }
  },

  methods: {
    ...mapActions({
      setAuthorization: 'setAuthorization',
      initializeSession: 'initializeSession',
      setLanguage: 'setLanguage',
      setLoading: 'setLoading',
      setNotificationsSeen: 'setNotificationsSeen',
      getThemeColors: 'getThemeColors',
      fetchEmployees: 'employees/fetchEmployees',
      fetchEmployeeDataOptions: 'employees/fetchEmployeeDataOptions',
      fetchEmployeePageLayout: 'layouts/fetchEmployeePageLayout',
      fetchEmployeeSummaryLayout: 'layouts/fetchEmployeeSummaryLayout',
      fetchEmployeeWidgetLayout: 'layouts/fetchEmployeeWidgetLayout',
      fetchOrganizations: 'organizations/fetchOrganizations',
      fetchOnboardingSettings: 'organizations/fetchOnboardingSettings'
    }),

    selectLanguage(language) {
      this.selectedLanguage = language;
      this.languageMenu = false;
    },

    getFlag(language) {
      return require('@/assets/images/flags/' + language.shortKey + '.png');
    },

    async logout() {
      await this.authService.logout();
    }
  }
};
</script>

<style scoped>
.theme--light.v-application {
  background-color: var(--v-background-base, white) !important;
}
.theme--dark.v-application {
  background-color: var(--v-background-dark, black) !important;
}
.v-toolbar__content {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.avatar-title {
  font-weight: bold !important;
  font-size: 1rem !important;
}
</style>
