const getOnboardingSettings = (state) => state.onboardingSettings;
const getOrganizations = (state) => state.organizations;
const getOrganization = (state, id) =>
  state.organizations.find((x) => x.id === id);

export default {
  getOrganization,
  getOrganizations,
  getOnboardingSettings
};
