import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    alias: '/',
    name: 'Dashboard',
    meta: {
      breadcrumbs: [{ label: 'Dashboard', icon: 'chart-bar' }],
      title: 'Dashboard'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/employees',
    name: 'EmployeesOverview',
    meta: {
      breadcrumbs: [{ label: 'employees', icon: 'chart-bar' }],
      title: 'routes.employeesOverview',
      hasHeroBar: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Employees/Overview.vue')
  },
  {
    path: '/onboarding',
    name: 'OnboardingOverview',
    meta: {
      breadcrumbs: [{ label: 'onboarding.name', icon: 'chart-bar' }],
      title: 'routes.onboardingOverview',
      hasHeroBar: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Onboarding/Overview.vue')
  },
  {
    path: '/statistics',
    name: 'StatisticsOverview',
    meta: {
      breadcrumbs: [{ label: 'statistics.name', icon: 'chart-bar' }],
      title: 'routes.statisticsOverview',
      hasHeroBar: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Statistics/Overview.vue')
  },
  {
    path: '/emails',
    name: 'EmailOverview',
    meta: {
      breadcrumbs: [{ label: 'emails', icon: 'mailbox' }],
      title: 'emailsOverview'
    },
    component: () =>
      import(
        /* webpackChunkName: "emailsOverview" */ '../views/Emails/Overview.vue'
      )
  },
  {
    path: '/emails/:id',
    name: 'EmailDetails',
    meta: {
      breadcrumbs: [
        { label: 'emails', icon: 'mailbox' },
        { label: 'emailDetails.breadcrumb', icon: 'email' }
      ],
      title: 'emailDetails.breadcrumb'
    },
    component: () =>
      import(
        /* webpackChunkName: "emailsDetails" */
        '../views/Emails/Details.vue'
      )
  },
  {
    path: '/employees/:id',
    name: 'EmployeeDetails',
    meta: {
      breadcrumbs: [{ label: 'employees', icon: 'account' }],
      title: 'routes.employeeDetails'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Employees/Details.vue')
  },
  {
    path: '/onboarding/:id',
    name: 'OnboardingDetails',
    meta: {
      breadcrumbs: [{ label: 'onboarding.name', icon: 'account' }],
      title: 'routes.onboardingDetails'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Onboarding/Details.vue')
  },
  {
    path: '/layouts/employee',
    name: 'DetailsLayout',
    meta: {
      breadcrumbs: [{ label: 'layouts.name', icon: 'chart-bar' }],
      title: 'Mitarbeiterdetails-Layout'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Layouts/Details.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/callback',
    name: 'OidcCallback',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcCallback" */
        '../views/oidc/Callback.vue'
      )
  },
  {
    path: '/silent-renew',
    name: 'OidcSilentRenew',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcSilentRenew" */
        '../views/oidc/SilentRenew.vue'
      )
  },
  {
    path: '/error-403',
    name: 'Error403',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 403'
    },
    component: () =>
      import(/* webpackChunkName: "error401" */ '../Error403.vue')
  },

  {
    path: '*',
    name: 'Error404',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 404'
    },
    component: () =>
      import(/* webpackChunkName: "error404" */ '../Error404.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;
