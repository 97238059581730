import api from '@/helpers/api.js';

export default {
  async getFileData(bucket, fileName) {
    return await api.get(`/File/Data?bucket=${bucket}&fileName=${fileName}`);
  },

  async getFile(bucket, fileName) {
    return await api.get(`/File?bucket=${bucket}&fileName=${fileName}`);
  },

  async saveFile(bucket, fileData, employeeId) {
    return await api.post('/File', {
      bucket: bucket,
      fileData: fileData,
      userId: employeeId
    });
  },

  async deleteFile(bucket, fileName) {
    return await api.post('/File/Delete', {
      bucket: bucket,
      fileName: fileName
    });
  }
};
