<template>
  <hp-hero>
    <template v-slot:title>
      <h1>{{ $t($route.meta.title) }}</h1>
    </template>
  </hp-hero>
</template>

<script>
export default {
  data: () => ({
    iteration: 0,
    items: []
  }),

  watch: {
    '$route.meta.breadcrumbs': {
      handler(breadcrumbs) {
        if (!breadcrumbs) {
          return [];
        }

        this.items = breadcrumbs.map((x) => {
          return {
            label: x.label,
            disabled: !x.to,
            icon: x.icon,
            text: x.text,
            to: x.to
          };
        });
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
