import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{attrs:{"to":{
    name: 'EmployeeDetails',
    params: { id: _vm.item.id }
  }},on:{"click":function($event){return _vm.$emit('click')}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.firstName)+" "+_vm._s(_vm.item.lastName))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }