const getTasksUpdated = (state) => {
  return state.tasksUpdated;
};

const getDatesUpdated = (state) => {
  return state.datesUpdated;
};

export default {
  getTasksUpdated,
  getDatesUpdated
};
