import api from '@/helpers/api.js';

export default {
  createOrganization(organizationData) {
    return api.postForm('/organization', organizationData);
  },

  async getOrganizations() {
    var response = await api.get('/organizations');
    return response.organizations;
  },

  async getOnboardingSettings() {
    var response = await api.get('/onboarding');
    return response.settings;
  }
};
